import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useAPI from "../../hooks/useAPI";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import logoLogin from "../../imgs/logo-login.png";
import logoLoginDark from "../../imgs/Sending_logo_dark.png";
import Spinner from "../plugins/Spinner";
import { FaEye } from "react-icons/fa";
import InstallPWA from "./installPWA";
import './css/login.css';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors }} = useForm({ defaultValues: { email: "", password: "" } });
  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  useEffect(() => {
    console.log("Removing USER_TOKEN because it enters to login page....");
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("USER_ROLEID");
    localStorage.removeItem("USER_ROLEDESCRIPTION");
    localStorage.removeItem("USER_ID");
    // localStorage.removeItem("IS_DARK");
    // localStorage.removeItem("IS_DARK");
    //connectSocket();
  }, []);
  
  const onShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const navigate = useNavigate();

  const postLogin = async (values) => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "auth/login";
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      console.log("Data Send::::::::::::::::::::::::::::::::", result);

      //# POST Completado, se reinician lo campos
      if (!result.token) {
        setLoading(false);
        Swal.fire({
          title: "¡Atención!",
          text: result.message,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      } else {
        localStorage.setItem("USER_TOKEN", result.token);
        localStorage.setItem("USER_NAME", result.nameUser);
        localStorage.setItem("USER_ROLEID", result.roleId);
        localStorage.setItem("USER_ROLEDESCRIPTION", result.roleDescription);
        localStorage.setItem("USER_ID", result.idUser);
        localStorage.setItem("COMPANY_ID", result.company);
        localStorage.setItem("USER_AREA", result.area);
        localStorage.setItem("IS_DARK", result.isDark);
        localStorage.setItem("COMPANY_PLAN", result.companyPlan);
      }

      if (result.token) {
        navigate("/home");
        // navigate('/');
      } else {
        return;
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div id="main-login">
        <div className="container-fluid">
          <main id="login-register" className="loggin">
            <header>
              <h1>
                <a href="#">
                  <img src={logoLogin} alt="Logo" className="logo-sendingup" />
                  <img src={logoLoginDark} alt="Logo" className="logo-sendingup-dark" />
                </a>
              </h1>
            </header>
            <section className="box login">
              <header>
                <h3>Ingresar</h3>
              </header>
              <div className="padd">
                <form onSubmit={handleSubmit(postLogin)}>
                  <label>Correo electrónico</label>
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Correo electrónico"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "* El email es requerido",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "* El formato no es correcto",
                      },
                    })}
                  />
                  {errors.email && <Alert>{errors.email.message}</Alert>}

                  <label>Contraseña</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control pr-4"
                    placeholder="Contraseña"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "* La contraseña es requerida.",
                      },
                    })}
                  />
                  <button
                    type="button"
                    onClick={onShowPassword}
                    className="show-password"
                  >
                    <FaEye />
                  </button>

                  {errors.password && <Alert>{errors.password.message}</Alert>}

                  <button type="submit" className="btn btn-primary btn-lg">
                    Iniciar sesión
                  </button>
                  <p style={{ fontSize: "14px" }}>
                    <a
                      href="javascript:;"
                      onClick={() => navigate("/forgot-password")}
                      title="Recuperar la contraseña"
                      rel="nofollow"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </p>
                </form>
                <div className="install-button-container">
                  <InstallPWA />
                </div>

              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default Login;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
