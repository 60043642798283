import React from "react";
import { Handle, Position } from 'reactflow';

import { BsDatabaseFillAdd } from "react-icons/bs";
import { FaEllipsisVertical } from "react-icons/fa6";

const QueryApi = ({ data, isConnectable, id }) => {

    const auth_types = [
        {
          label: 'Ninguna',
          id: 'none'
        },
        {
          label: 'Usuario/Contraseña',
          id: 'user_password'
        },
    ]
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable}  style={{backgroundColor: '#7ECCD8'}}/>
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#7ECCD8", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <BsDatabaseFillAdd className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Consulta API"}
                        </span>
                    </div>
                    {!data.first && (
                        <div className="dropdown">
                            <button
                                className="bg-transparent border-0 p-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <FaEllipsisVertical className="text-white pointer mt-1" />
                            </button>
                            <div
                                className="dropdown-menu"
                                style={{minWidth: "60px"}}
                                aria-labelledby="dropdownMenuButton"
                            > 
                                <button 
                                    className="text-danger border-0 bg-transparent pointer" 
                                    style={{height: "20px"}} 
                                    onClick={() => {data.onRemove(id)}}
                                >
                                    <span className="text-dark ml-1 f-10">
                                        Eliminar
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(126, 204, 216, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    <div 
                        className="p-1 mb-2" 
                        style={{
                            border: "1px solid #000",
                            borderRadius: "4px",
                            height: "auto"
                        }}
                    >   
                        <div>
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Protocolo HTTP:</span>
                            {data.protocol && (
                                <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                    {data.protocol}
                                </p>
                            )}
                        </div>
                        <div>
                          <hr
                            style={{margin: 0}}
                            role="separator"
                            aria-orientation="horizontal"
                            className="v-divider theme--light"
                          />
                        </div>
                        <div>
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Tipo de autenticación:</span>
                            {data.auth && (
                                <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                    {auth_types.find(v => v.id == data.auth).label}
                                </p>
                            )}
                        </div>
                        <div>
                          <hr
                            style={{margin: 0}}
                            role="separator"
                            aria-orientation="horizontal"
                            className="v-divider theme--light"
                          />
                        </div>
                        <div>
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>URL API extrena:</span>
                            {data.url_api && (
                                <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                    {data.url_api}
                                </p>
                            )}
                        </div>
                    </div>
                    {data.bot_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                    <div 
                        className="p-1 mb-2" 
                        style={{
                            border: "1px solid #000",
                            borderRadius: "4px",
                            height: "90px"
                        }}
                    >   
                        <span style={{fontWeight: "bold", fontSize: "9px"}}>Estados de la solicitud:</span>
                    </div>
                    {data.tags && data.tags.length > 0 && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <BsDatabaseFillAdd className="mr-1" style={{fontSize: "12px", color: "#000"}}/>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Etiquetas:
                                </span>
                            </div>
                            {data.tags.map(tag => (
                                <div className="d-flex align-items-center">
                                    <p 
                                        style={{fontWeight: "bold", fontSize: "9px", backgroundColor: "#E6E6E6", borderRadius: "4px"}} 
                                        className="m-0 p-1 mb-1"
                                    >
                                        {tag.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default QueryApi;